.screen-page {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 2;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  transform-origin: center center;
  .widget {
    position: absolute;
  }
  .widget-image {
    .image-box {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
      transform: translate3d(0, 0, 0);
      transform-style: preserve-3d;
    }
    .image-slide {
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
    }
  }
  .widget-text .text-content {
    word-break: break-word;
  }
  .widget-video video {
    width: 100%;
    height: 100%;
  }
  .widget-table {
    display: flex;
    flex-direction: column;
    line-height: 1;
    .table-row {
      flex: 1;
      display: flex;
      align-items: center;
      overflow: hidden;
      &:first-child {
        border-top: none !important;
      }
    }
    .table-col {
      height: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      word-break: break-all;
      &:first-child {
        border-left: none !important;
      }
      span {
        word-break: break-all;
        font-family: inherit;
      }
    }
  }
}
.error-message {
  position: fixed;
  left: 50%;
  top: 50%;
  background-color: transparent;
  z-index: 3;
  transform-origin: center center;
  .error-text {
    position: absolute;
    bottom: 50px;
    left: 50%;
    font-size: 25px;
    color: #fff;
    background-color: rgba($color: #000000, $alpha: 0.4);
    line-height: 1;
    padding: 10px 20px;
    border-radius: 10px;
    transform: translateX(-50%);
    &.fade {
      opacity: 1;
      animation-name: fade;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }
  }
}
.options-mask {
  position: fixed;
  left: 50%;
  top: 50%;
  background-color: transparent;
  z-index: 10;
  transform-origin: center center;
  .options-button {
    position: absolute;
    top: 0;
    width: 200px;
    height: 200px;
    background-color: rgba($color: #000000, $alpha: 0.6);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    line-height: 1;
    padding-bottom: 40px;
    opacity: 0;
    transition: opacity 0.15s;
    &:hover {
      opacity: 1;
    }
    &:focus, &:active {
      opacity: 0.7;
    }
    &.first {
      left: 0;
      padding-right: 40px;
      border-bottom-right-radius: 200px;
    }
    &.second {
      right: 0;
      padding-left: 40px;
      border-bottom-left-radius: 200px;
    }
    &.third {
      left: 0;
      top: auto;
      bottom: 0;
      padding: 40px 40px 0 0;
      border-top-right-radius: 200px;
    }
    &.four {
      right: 0;
      top: auto;
      bottom: 0;
      padding: 40px 0 0 40px;
      border-top-left-radius: 200px;
    }
  }
}
.loading {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 200px 200px;
}
@keyframes fade
{
  0% { opacity: 0.1; }
  50% { opacity: 1; }
  100% { opacity: 0.1; }
}
