$minWidth: 1000px;
$maxWidth: 1300px;
$minHeight: 800px;

$theme: #2F80ED;
$white: #ffffff;
$black: #000000;
$gray: #999999;
$yellow: #FED54F;
$yellow1: #FEB84F;
$yellow2: #DB8402;
$blue: #5AA0FF;
$blue1: #83FBFE;
$blue2: #1E63C1;
$blue3: #DEEFFF;
$blue4: #3A5E8F;
$blue5: #246DD1;
$blue6: #4BC5E9;
$blue7: #176ADB;
$blue8: rgba(90, 160, 255, 0.5);
$blue9: #43FEFE;
$blue10: #43DCFE;