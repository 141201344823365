@import './config';
@import './flex';
@import './common';
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-button {
  display: none;
}
::-webkit-scrollbar-track {
  background: $gray;
}
::-webkit-scrollbar-track-piece {
  background: $white;
}
::-webkit-scrollbar-thumb {
  background: $gray;
  border-radius: 4px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
}
a {
  text-decoration: none;
  color: inherit;
}
html, body {
  position: relative;
  overflow: hidden;
  height: 100%;
  font-family: "SF Pro SC", "HanHei SC", "SF Pro Text", "Myriad Set Pro", "SF Pro Icons",
    "PingFang SC", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 10px;
  color: $white;
  @media screen and (max-width: 1200px) {
    font-size: 9px;
  }
  @media screen and (max-width: 1100px) {
    font-size: 8px;
  }
  @media screen and (max-width: 750px) {
    font-size: 10px;
  }
  @media screen and (max-width: 380px) {
    font-size: 9px;
  }
  @media screen and (max-width: 330px) {
    font-size: 8px;
  }
  &>div {
    position: relative;
    height: 100%;
    overflow: auto;
  }
}
.app-page {
  position: relative;
  height: 100%;
  .page-container {
    width: 100%;
    max-width: $maxWidth;
    margin: 0 auto;
    padding: 0 3rem;
    position: relative;
    z-index: 10;
  }
}
.mobile-page {
  position: relative;
  height: 100%;
}