@font-face {font-family: "iconfont";
  src: url('iconfont.eot?t=1619343326521'); /* IE9 */
  src: url('iconfont.eot?t=1619343326521#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('data:application/x-font-woff2;charset=utf-8;base64,d09GMgABAAAAAALcAAsAAAAABtwAAAKNAAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHEIGVgCDBgqBQIE6ATYCJAMMCwgABCAFhG0HPBsGBsiusCnD9kiJeO09YOG4DQoA8Llj8fDffr/fZ2buRaVBcqm+Okk1UYIlEpROJpMK2f4v4f3vmv4yqKkMJCCqluVB7nqYvjRjFoY0gSIjNje7yrm5LQECMloLEBVZ/n8NZDLA//9cTm81NZD5LQsstTVt7MlHvQDjgAIba1JkBTpBvGHsApewnUCrYbUsO+OzKXArrH6BuFdkDtw5v6KwVLNQ10wt4k2lOb2kDwCv4fPjPzTnJqkya+ju3ZgIg9/wk2qzoRMfESDA2RUyZoBCnNXGD9gEY9m0au3tubUipLlCgFCq6+ud/vESUbOK28EETCS+wRUQfKdukEAGNQrWgC1I6Y+EXHfj169lsZiRVyKXt+ChmvDVXYXl4j0fOclXoL/+wffX9xd/dcrA+RzN6CzmiZ2h5sYjqye99OWbYyojfso8tIkabu0CoMG2rT383bZtCQQffNFDvf9uXwFf97ky8DXY9TdqPqcj+IeynlVFV1iGXFSpKzWd2cL9WBNQzF0aWe5e5fH6gd5RQnNOxiBpMgRZsxG0UGag0mYFas3WodW0idlt+lA2UVowZQ9A6PYMSadPyLp9ooXyDZVBv1Drzgat9iK2ZJuxFO8IxRzDIuKXkCIQU3JMzBuVJrA8p3M0qwjZGUxLSgg11TcWUxPYxHSOEaV5uZkxCUmUGGgc7IZ1naAiJSoWWH2BsWJ3Q4NUd6d6gRjgtY3COAwmQnhLEIWAMEl+fcZbenwCJpuj49AWUk04A6NKlOaRJvUae6ATWrMX6V5uKZkna8YwEkRCEQZkHFgx3Y4TSLG+kwoTMPUKI6JF3RpQLamvtn59vfF9G6CVdU6OFDmKtktKyUIdVeQCsxvYnAMAAAAA') format('woff2'),
  url('iconfont.woff?t=1619343326521') format('woff'),
  url('iconfont.ttf?t=1619343326521') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
  url('iconfont.svg?t=1619343326521#iconfont') format('svg'); /* iOS 4.1- */
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: inherit;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-right:before {
  content: "\e600";
}

.icon-menu:before {
  content: "\e654";
}

