.box {
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    display: inline-block;
    width: 0.1px;
    height: 0;
    padding-bottom: 100%;
    vertical-align: middle;
  }
  .box-content {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;
  }
}

.background-image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.one-line-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.two-line-ellipsis {
  text-align: left !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.transition {
  transform: translate3d(0px, 0px, 0px);
  transition: all 0.3s;
}

.user-select {
  user-select: text;
}

.cursor-pointer {
  cursor: pointer;
}
