@import '../../styles/config';
.app-header {
  position: fixed;
  width: 100%;
  min-width: $minWidth;
  left: 0;
  top: 0;
  z-index: 100;
  line-height: 1;
  transform: translate3d(0px, 0px, 0px);
  transition: all 0.3s;
  @media screen and (max-width: $minWidth) {
    position: absolute;
    background-color: transparent !important;
    color: inherit !important;
  }
  &.active {
    background-color: $white;
    color: $black;
    .header-tab .tab-item {
      &:hover {
        color: lighten($color: $theme, $amount: 20%);
      }
      &.active {
        color: $theme;
        border: 1px solid $theme;
      }
    }
  }
  .header-container {
    width: 100%;
    max-width: $maxWidth;
    padding: 1rem 3rem;
  }
  .header-logo {
    width: 6.4rem;
    height: 6.4rem;
    margin-right: 0.8rem;
  }
  .header-title {
    font-size: 2.4rem;
    font-weight: 500;
  }
  .header-tab {
    overflow: hidden;
    .tab-item {
      width: 10rem;
      height: 4.6rem;
      border-radius: 2.3rem;
      margin: 0 1rem;
      font-size: 1.4rem;
      font-weight: 500;
      cursor: pointer;
      border: 1px solid transparent;
      overflow: hidden;
      &:hover {
        color: lighten($color: $yellow, $amount: 20%);
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &.active {
        color: $yellow;
        border: 1px solid $yellow;
      }
    }
  }
}