@import '../../styles/config';
.app-page.page-index {
  background-color: $theme;
  min-width: $minWidth;
  min-height: $minHeight;
  overflow-x: hidden;
  overflow-y: auto;
  .tab-title {
    font-size: 4.8rem;
    font-weight: 500;
    color: $white;
    text-align: center;
  }
  .tab-discription {
    font-size: 1.8rem;
    text-align: center;
    margin-top: 3rem;
  }
  .tab-home {
    height: 100vh;
    min-height: $minHeight;
    position: relative;
    overflow: hidden;
    .page-container {
      height: 100%;
    }
    .home-title {
      font-size: 3.8rem;
      font-weight: 500;
    }
    .home-subtitle {
      height: 3.4rem;
      font-size: 2.4rem;
      margin-top: 2rem;
      line-height: 1;
      .subtitle-text {
        margin-right: 0.8rem;
      }
      .subtitle-slogan {
        height: 100%;
        font-weight: 600;
        position: relative;
        .slogan-content {
          position: absolute;
          height: 100%;
          left: 0;
          top: 0;
          white-space: nowrap;
          opacity: 0;
          visibility: hidden;
          transform: rotateX(90deg);
          &.active {
            transform: rotateX(0);
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
    .home-introduction {
      max-width: 46rem;
      line-height: 2.5rem;
      font-size: 1.4rem;
      margin-top: 1rem;
    }
    .home-tag {
      width: 27rem;
      line-height: 1;
      font-size: 1.4rem;
      background-color: $blue;
      padding: 0.5rem;
      box-shadow: -0.6rem 0.6rem 0px $blue2;
      border-radius: 0.4rem;
      margin-top: 3rem;
      .tag-list {
        font-weight: 500;
        color: $yellow;
        .tag-item {
          height: 2.5rem;
          background-color: $theme;
          &:not(:first-child) {
            margin-left: 0.5rem;
          }
        }
      }
      .tab-info {
        height: 2.8rem;
        color: $blue2;
        background-color: $blue1;
        margin-top: 0.5rem;
        border-radius: 0px 0px 0.4rem 0.4rem;
      }
    }
    .home-tip {
      font-size: 1.8rem;
      font-weight: 600;
      color: $blue1;
      margin-top: 1rem;
    }
    .home-button {
      width: 26.6rem;
      height: 5rem;
      font-size: 1.8rem;
      font-weight: 500;
      line-height: 1;
      margin-top: 3rem;
      color: $yellow2;
      background-color: $yellow;
      box-shadow: 0px 0px 0px 0.4rem $blue2;
      border-radius: 2.7rem;
      cursor: pointer;
      overflow: hidden;
      &:hover {
        background-color: lighten($color: $yellow, $amount: 5%);
      }
      .button-arrow {
        width: 7rem;
        background-color: $yellow1;
        .iconfont {
          font-size: 2.2rem;
          color: $yellow2;
        }
      }
    }
  }
  .tab-about {
    background-color: $white;
    padding: 7rem 0;
    .tab-title {
      color: $theme;
      margin-bottom: 7rem;
    }
    .about-block {
      border-radius: 0.6rem;
      background-color: $blue3;
      overflow: hidden;
      &:not(:first-child) {
        margin-left: 4.5%;
      }
      &::before {
        padding-bottom: 126.8%;
      }
      .box-content {
        padding: 0 7%;
      }
      .block-image {
        width: 97%;
        height: auto;
      }
      .block-title {
        font-size: 2.4rem;
        font-weight: 500;
        color: $theme;
        margin-top: 8%;
      }
      .block-introduction {
        font-size: 1.8rem;
        color: $blue4;
        text-align: center;
        margin-top: 9%;
      }
    }
  }
  .tab-solution {
    padding-top: 7rem;
    .tab-title {
      margin-bottom: 3rem;
    }
    .solution-block {
      min-width: 28%;
      background-color: $blue5;
      border-radius: 0.6rem;
      overflow: hidden;
      margin-top: 4.2%;
      &::before {
        padding-bottom: 72.3%;
      }
      &:not(:nth-child(3n + 1)) {
        margin-left: 4.5%;
      }
      .box-content {
        padding: 6.5% 7%;
      }
      .block-image {
        position: absolute;
        top: 8%;
        right: 5%;
        width: 60%;
        height: auto;
      }
      .block-title {
        font-size: 2.4rem;
        font-weight: 500;
      }
      .block-introduction {
        font-size: 1.4rem;
        max-width: 75%;
      }
    }
  }
  .tab-feature {
    padding-top: 7rem;
    padding-bottom: 24rem;
    .tab-title {
      margin-bottom: 3rem;
    }
    .feature-block-wrapper {
      padding-right: 21.5%;
      transform: translateY(25%);
      position: relative;
      .block-shadow {
        position: absolute;
        background-color: $blue5;
        transform: rotate(-45deg);
        &.shadow1 {
          width: 10rem;
          height: 10rem;
          border-radius: 1.6rem;
          left: 2%;
          top: 32.5%;
        }
        &.shadow2 {
          width: 6rem;
          height: 6rem;
          border-radius: 1.4rem;
          right: 40.5%;
          top: -4.5%;
        }
        &.shadow3 {
          width: 6rem;
          height: 6rem;
          border-radius: 1.4rem;
          right: 10%;
          bottom: 19%;
        }
        &.shadow4 {
          width: 4rem;
          height: 4rem;
          border-radius: 0.8rem;
          left: 26%;
          bottom: 20%;
        }
      }
      .feature-block {
        min-width: 20%;
        font-size: 1.4rem;
        background-color: $blue8;
        border-radius: 2rem;
        transform: rotate(-45deg) translateX(25%);
        &:not(:nth-child(3n + 1)) {
          margin-left: 13%;
        }
        &:nth-child(4), &:nth-child(5), &:nth-child(6) {
          transform: rotate(-45deg) translate(88%, 44%);
        }
        &:nth-child(7), &:nth-child(8), &:nth-child(9) {
          transform: rotate(-45deg) translate(44%, -20%);
        }
        &:nth-child(2), &:nth-child(5), &:nth-child(8) {
          .block-angle {
            background-color: $blue9;
          }
          .block-border.border1 .block-angle {
            background-color: $blue10;
          }
        }
        .box-content {
          transform: rotate(45deg);
        }
        .block-icon {
          width: 4rem;
          height: 4rem;
          margin-top: 5%;
        }
        .block-title {
          font-size: 3.6rem;
          font-weight: 500;
          margin: 12% 0 5%;
        }
        .block-introduction {
          max-width: 90%;
        }
        .block-border {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          border: 0.8rem solid $blue;
          border-radius: 2rem;
          overflow: hidden;
          &.border1 {
            border: none;
            .block-angle {
              background-color: $yellow1;
              transform: rotate(45deg) translate(0%, 91%);
            }
          }
          &.border2 {
            border-color: transparent;
          }
        }
        .block-angle {
          position: absolute;
          width: 100%;
          height: 100%;
          right: 0;
          bottom: 0;
          z-index: 10;
          background-color: $yellow;
          transform: rotate(45deg) translate(0%, 94%);
        }
      }
    }
  }
  .tab-reputation {
    padding-top: 7rem;
    padding-bottom: 4rem;
    font-size: 1.8rem;
    .reputation-swiper {
      height: 57rem;
      .reputation-swiper-slide {
        padding-bottom: 7rem;
      }
    }
    .reputation-avatar {
      width: 13rem;
      height: 13rem;
      border-radius: 50%;
      border: 1.2rem solid $blue;
      background-color: $blue7;
      box-shadow: 0px 0px 0px 12px rgba(90, 160, 255, 0.5);
    }
    .reputation-title {
      font-weight: 600;
      margin-top: 3rem;
    }
    .reputation-introduction {
      margin-top: 6rem;
      max-width: 90rem;
      text-align: center;
      line-height: 2.1;
    }
    .swiper-pagination {
      bottom: 0;
      .swiper-pagination-bullet {
        width: 5rem;
        height: 0.6rem;
        border-radius: 0.3rem;
        background-color: $blue7;
        opacity: 1;
        position: relative;
        overflow: hidden;
        &::after{
          content: '';
          position: absolute;
          width: 0;
          height: 100%;
          left: 0;
          top: 0;
          background-color: $white;
          transition: none;
          transform: translate3d(0px, 0px, 0px);
        }
      }
      .swiper-pagination-bullet-active {
        background-color: $blue7;
        &::after {
          width: 100%;
          transition: all 3s linear;
        }
      }
    }
  }
  .tab-contact {
    padding-top: 7rem;
    padding-bottom: 7rem;
    font-size: 1.4rem;
    background-color: $blue7;
    .contact-title {
      font-size: 2.4rem;
      font-weight: 500;
      margin-bottom: 3.5rem;
    }
    .contact-button {
      width: 24.3rem;
      height: 3.2rem;
      line-height: 1;
      border-radius: 0.6rem;
      background-color: $theme;
      margin-top: 0.5rem;
      cursor: pointer;
    }
    .contact-qrcode {
      margin-left: 7rem;
      .qrcode-image {
        width: 17rem;
        height: 17rem;
        border: 0.8rem solid $theme;
        border-radius: 0.6rem;
        margin-bottom: 0.6rem;
      }
    }
  }
  .index-footer {
    padding: 3rem 0;
    font-size: 1.4rem;
    background-color: $blue2;
    .footer-statement {
      font-size: 1rem;
      line-height: 1;
      margin-bottom: 0.8rem;
      .statement-tag {
        padding: 0.2rem 0.8rem;
        margin: 0 0.3rem;
        color: $blue6;
        border: 1px solid $blue6;
        border-radius: 0.8rem;
      }
    }
    a {
      margin-top: 0.3rem;
    }
  }
}