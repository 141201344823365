@import '../../styles/config';
.page-not-found {
  min-width: 800px;
  background-color: $theme;
  line-height: 1;
  overflow: hidden;
  .not-found-container {
    height: 400px;
    position: relative;
    top: 50%;
    margin: -200px auto 0;
    text-align: center;
    .code {
      font-size: 300px;
      font-weight: 600;
    }
    .tip {
      font-size: 30px;
      margin-top: 20px;
    }
  }
}