@import '../../styles/config';
.page-tip {
  min-width: 800px;
  background-color: $theme;
  line-height: 1;
  overflow: hidden;
  vertical-align: middle;
  .tip-container {
    position: relative;
    height: 300px;
    top: 50%;
    margin: -150px auto 0;
    text-align: center;
    .tip-title {
      font-size: 60px;
      font-weight: 500;
    }
    .tip-text {
      font-size: 20px;
      margin-top: 20px;
    }
  }
  .browser-list {
    overflow: hidden;
    margin-top: 30px;
    .browser-item {
      display: inline-block;
      margin: 0 15px;
      font-size: 15px;
      .browser-image {
        width: 100px;
        height: 100px;
        margin-bottom: 10px;
      }
    }
  }
}