.flex { display: flex; }
.flex-align {
  @extend .flex;
  align-items: center;
}
.flex-justify {
  @extend .flex;
  justify-content: center;
}
.flex-justify-right {
  @extend .flex;
  justify-content: flex-end;
}
.flex-align-justify {
  @extend .flex;
  align-items: center;
  justify-content: center;
}
.flex-column {
  @extend .flex;
  flex-direction: column;
}
.flex-column-align {
  @extend .flex-align;
  flex-direction: column;
}
.flex-column-justify {
  @extend .flex-justify;
  flex-direction: column;
}
.flex-column-align-justify {
  @extend .flex-align-justify;
  flex-direction: column;
}
.flex-form {
  @extend .flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.flex-align-start { align-items: flex-start; }
.flex-align-end { align-items: flex-end; }
.flex-justify-center { justify-content: center; }
.flex-justify-start { justify-content: flex-start; }
.flex-justify-end { justify-content: flex-end; }
.flex-wrap { flex-wrap: wrap; }
.flex-one { flex: 1; }
.flex-none { flex: none !important; }
.flex-row-reverse { flex-direction: row-reverse; }