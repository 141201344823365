@import '../../styles/config';
.mobile-page.page-index {
  &::-webkit-scrollbar {
    width: 0;
  }
  background-color: $theme;
  overflow-x: hidden;
  overflow-y: auto;
  .mobile-header {
    position: fixed;
    width: 100%;
    height: 4.6rem;
    left: 0;
    top: 0;
    z-index: 100;
    padding: 0 1rem;
    line-height: 1;
    transform: translate3d(0px, 0px, 0px);
    transition: all 0.3s;
    &.active {
      background-color: $white;
      color: $black;
    }
    .header-logo {
      width: 4.6rem;
      height: 4.6rem;
      margin-right: 0.4rem;
    }
    .header-title {
      font-size: 1.4rem;
      font-weight: 500;
    }
    .header-menu {
      font-size: 2.6rem;
    }
  }
  .background {
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 100% auto;
  }
  .swiper-pagination {
    bottom: 4%;
    .swiper-pagination-bullet {
      width: 3rem;
      height: 0.2rem;
      border-radius: 0.1rem;
      background-color: $blue7;
      opacity: 1;
      position: relative;
      overflow: hidden;
      &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 100%;
        left: 0;
        top: 0;
        background-color: $white;
        transition: none;
        transform: translate3d(0px, 0px, 0px);
      }
    }
    .swiper-pagination-bullet-active {
      background-color: $blue7;
      &::after {
        width: 100%;
        transition: all 3s linear;
      }
    }
  }
  .tab-title {
    font-size: 2.4rem;
    font-weight: 500;
    color: $white;
    text-align: center;
  }
  .tab-discription {
    font-size: 1.3rem;
    margin-top: 2rem;
  }
  .tab-home {
    height: 100vh;
    position: relative;
    overflow: hidden;
    .home-container {
      position: relative;
      overflow: hidden;
      background-position: center bottom 38%;
      background-repeat: no-repeat;
      background-size: 90% auto;
      .home-title {
        font-size: 2.4rem;
        font-weight: 500;
        margin-top: 16%;
        @media screen and (min-height: 800px) {
          margin-top: 25%;
        }
      }
      .home-introduction {
        font-size: 1.3rem;
        text-align: center;
        line-height: 1.8;
        margin-top: 2%;
      }
      .home-slogan {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 10%;
        font-size: 1.4rem;
        line-height: 1;
        z-index: 10;
        .slogan-content {
          position: absolute;
          height: 2.4rem;
          left: 50%;
          top: 0;
          padding: 0.2rem 0.5rem;
          background-color: $blue2;
          border-radius: 0.2rem;
          white-space: nowrap;
          opacity: 0;
          visibility: hidden;
          transform: translateX(-50%) rotateX(90deg);
          &.active {
            transform: translateX(-50%) rotateX(0);
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
    .home-swiper {
      width: 100%;
      margin-top: 7%;
      overflow: hidden;
      @media screen and (max-width: 330px) {
        margin-top: 5%;
      }
      @media screen and (max-height: 600px) {
        margin-top: 5%;
      }
      .home-swiper-slide {
        padding-bottom: 15rem;
      }
      .swiper-window {
        width: 86%;
        @media screen and (max-height: 600px) {
          width: 80%;
        }
        position: relative;
        .window-container {
          border: 0.6rem solid $blue;
          border-radius: 1.8rem;
          z-index: 1;
          @extend .background;
          &::before {
            padding-bottom: 63.2%;
          }
        }
        .window-border {
          position: absolute;
          left: -1.4rem;
          top: 1.6rem;
          width: 100%;
          height: 100%;
          border: 0.6rem solid $blue2;
          border-radius: 1.8rem;
        }
        .window-small {
          position: absolute;
          width: 73.4%;
          left: 8rem;
          bottom: -7rem;
          z-index: 10;
          border: 0.6rem solid $blue;
          border-radius: 1.2rem;
          z-index: 2;
          filter: drop-shadow(-6px 6px 0px rgba(0, 0, 0, 0.25));
          @extend .background;
          &::before {
            padding-bottom: 62%;
          }
        }
        .window-mini {
          position: absolute;
          width: 34.7%;
          left: 8rem;
          bottom: 5rem;
          z-index: 10;
          border: 0.6rem solid $blue;
          border-radius: 1.2rem;
          z-index: 2;
          filter: drop-shadow(-6px 6px 0px rgba(0, 0, 0, 0.25));
          @extend .background;
          &::before {
            padding-bottom: 69.7%;
          }
        }
      }
      .swiper-phone-wrapper {
        width: 100%;
        height: 100%;
        @media screen and (max-height: 600px) {
          width: 80%;
        }
        .swiper-phone {
          width: 36%;
          position: relative;
          &:last-child {
            margin-left: 8%;
            transform: translateY(6%);
          }
          .phone-container {
            border: 0.6rem solid $blue;
            border-radius: 1.8rem;
            z-index: 1;
            @extend .background;
            &::before {
              padding-bottom: 175%;
            }
          }
          .phone-border {
            position: absolute;
            left: -1rem;
            top: 2rem;
            width: 100%;
            height: 107%;
            border: 0.6rem solid $blue2;
            border-radius: 1.8rem;
          }
        }
      }
    }
    .home-footer {
      padding: 1.4rem 0 5.5rem;
      background-color: $white;
      .home-tag {
        width: 27rem;
        line-height: 1;
        font-size: 1.4rem;
        background-color: $blue;
        padding: 0.5rem;
        box-shadow: -0.6rem 0.6rem 0px $blue2;
        border-radius: 0.4rem;
        .tag-list {
          font-weight: 500;
          color: $yellow;
          .tag-item {
            height: 2.5rem;
            background-color: $theme;
            &:not(:first-child) {
              margin-left: 0.5rem;
            }
          }
        }
        .tab-info {
          height: 2.8rem;
          color: $blue2;
          background-color: $blue1;
          margin-top: 0.5rem;
          border-radius: 0px 0px 0.4rem 0.4rem;
        }
      }
      .home-tip {
        font-size: 1.8rem;
        color: $theme;
        margin: 1rem auto 0;
      }
      .home-button {
        position: fixed;
        bottom: 1rem;
        left: 50%;
        z-index: 99;
        width: 26rem;
        height: 4rem;
        transform: translateX(-50%);
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 1;
        color: $yellow2;
        background-color: $yellow;
        box-shadow: 0px 0.4rem 0.8rem rgba(219, 132, 2, 0.4);
        border-radius: 2rem;
        cursor: pointer;
        overflow: hidden;
        &:hover {
          background-color: lighten($color: $yellow, $amount: 5%);
        }
        .button-arrow {
          width: 7rem;
          background-color: $yellow1;
          .iconfont {
            font-size: 2.2rem;
            color: $yellow2;
          }
        }
      }
    }
  }
  .tab-about {
    padding: 1rem 1rem 2rem;
    background-color: $white;
    overflow: hidden;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1rem;
      left: 0;
      top: -0.5rem;
      background-color: $white;
    }
    .tab-title {
      color: $theme;
      margin-bottom: 2rem;
    }
    .about-block {
      height: 13rem;
      border-radius: 0.4rem;
      background-color: $blue3;
      padding: 0 1.8rem;
      margin-top: 0.6rem;
      overflow: hidden;
      .block-image {
        width: 25%;
        height: auto;
        margin-right: 1.5rem;
      }
      .block-title {
        color: $theme;
        font-size: 1.4rem;
        font-weight: 500;
      }
      .block-introduction {
        font-size: 1.2rem;
        line-height: 1.5;
        color: $blue4;
        margin-top: 1rem;
      }
    }
  }
  .tab-solution {
    padding: 3rem 1rem 2rem;
    overflow: hidden;
    .tab-discription {
      text-align: justify;
      margin-bottom: 1.5rem;
    }
    .solution-block {
      min-width: 40%;
      background-color: $blue5;
      border-radius: 0.6rem;
      margin-top: 5%;
      overflow: visible;
      &::before {
        padding-bottom: 72.3%;
      }
      &:not(:nth-child(2n + 1)) {
        margin-left: 2.5%;
      }
      .box-content {
        padding: 6.5% 7%;
      }
      .block-image {
        position: absolute;
        top: -7%;
        right: -3%;
        width: 60%;
        height: auto;
      }
      .block-title {
        font-size: 1.4rem;
        font-weight: 500;
      }
      .block-introduction {
        font-size: 1.2rem;
        margin-top: 0.8rem;
        text-align: justify;
      }
    }
  }
  .tab-feature {
    padding-top: 2rem;
    .tab-discription {
      text-align: center;
    }
    .feature-block-wrapper{ 
      transform: translateY(7%);
      position: relative;
      text-align: center;
      .block-shadow {
        position: absolute;
        background-color: $blue5;
        transform: rotate(-45deg);
        &.shadow1 {
          width: 4rem;
          height: 4rem;
          border-radius: 0.6rem;
          left: 45%;
          top: 0.6%;
        }
        &.shadow2 {
          width: 2.6rem;
          height: 2.6rem;
          border-radius: 0.6rem;
          left: 17.5%;
          top: 20.5%;
        }
        &.shadow3 {
          width: 2.6rem;
          height: 2.6rem;
          border-radius: 0.6rem;
          right: 17.5%;
          top: 47.5%;
        }
        &.shadow4 {
          width: 2.6rem;
          height: 2.6rem;
          border-radius: 0.6rem;
          left: 22%;
          bottom: 18.5%;
        }
      }
      .feature-block {
        display: inline-block;
        width: 34%;
        font-size: 1rem;
        background-color: $blue8;
        border-radius: 0.8rem;
        transform: rotate(-45deg);
        margin: 0 7.5%;
        &:nth-child(3), &:nth-child(6), &:nth-child(9) {
          display: block;
          margin: 0 auto;
        }
        &:nth-child(3) {
          transform: rotate(-45deg) translate(14%, -14%);
        }
        &:nth-child(4), &:nth-child(5) {
          transform: rotate(-45deg) translate(26%, -26%);
        }
        &:nth-child(6) {
          transform: rotate(-45deg) translate(40%, -40%);
        }
        &:nth-child(7), &:nth-child(8) {
          transform: rotate(-45deg) translate(52%, -52%);
        }
        &:nth-child(9) {
          transform: rotate(-45deg) translate(66%, -66%);
        }
        &:nth-child(2), &:nth-child(5), &:nth-child(8) {
          .block-angle {
            background-color: $blue9;
          }
          .block-border.border1 .block-angle {
            background-color: $blue10;
          }
        }
        .box-content {
          transform: rotate(45deg);
        }
        .block-icon {
          width: 2.3rem;
          height: 2.3rem;
          margin-top: 5%;
        }
        .block-title {
          font-size: 1.8rem;
          font-weight: 500;
          margin: 12% 0 5%;
        }
        .block-introduction {
          max-width: 90%;
        }
        .block-border {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          border: 0.4rem solid $blue;
          border-radius: 0.8rem;
          overflow: hidden;
          &.border1 {
            border: none;
            .block-angle {
              background-color: $yellow1;
              transform: rotate(45deg) translate(0%, 91.5%);
            }
          }
          &.border2 {
            border-color: transparent;
          }
        }
        .block-angle {
          position: absolute;
          width: 100%;
          height: 100%;
          right: 0;
          bottom: 0;
          z-index: 10;
          background-color: $yellow;
          transform: rotate(45deg) translate(0%, 94%);
        }
      }
    }
  }
  .tab-reputation {
    position: relative;
    padding-bottom: 2rem;
    font-size: 1.2rem;
    .reputation-swiper {
      height: 34rem;
      .swiper-pagination {
        bottom: 0;
      }
    }
    .reputation-avatar {
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
      border: 1.2rem solid $blue;
      background-color: $blue7;
      box-shadow: 0px 0px 0px 12px rgba(90, 160, 255, 0.5);
    }
    .reputation-title {
      font-size: 1.8rem;
      font-weight: 600;
      margin-top: 3rem;
    }
    .reputation-introduction {
      margin-top: 2rem;
      max-width: 90%;
      text-align: justify;
      line-height: 2;
    }
  }
  .tab-contact {
    position: relative;
    padding: 2rem 1rem;
    background-color: $blue7;
    font-size: 1.2rem;
    line-height: 1.5;
    .tab-title {
      margin-bottom: 1.5rem;
    }
    .contact-button {
      width: 22rem;
      height: 2.6rem;
      line-height: 1;
      border-radius: 0.2rem;
      background-color: $theme;
      margin-top: 0.3rem;
      cursor: pointer;
    }
    .contact-qrcode-wrapper {
      width: 22rem;
    }
    .contact-qrcode {
      margin-top: 2rem;
      .qrcode-image-wrapper {
        position: relative;
        width: 8.2rem;
        height: 8.2rem;
        border: 0.4rem solid $theme;
        border-radius: 0.4rem;
        margin-bottom: 0.4rem;
        overflow: hidden;
        &.active {
          .qrcode-active {
            z-index: 1;
          }
        }
        .qrcode-image {
          position: absolute;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .index-footer {
    padding: 1.6rem 0 6rem;
    font-size: 1rem;
    background-color: $blue2;
    .footer-statement {
      font-size: 0.9rem;
      line-height: 1;
      margin-bottom: 0.5rem;
      .statement-tag {
        padding: 1px 0.7rem;
        margin: 0 0.3rem;
        color: $blue6;
        border: 1px solid $blue6;
        border-radius: 0.8rem;
      }
    }
    a {
      margin-top: 0.2rem;
    }
  }
}