@import '../../styles/config';
@keyframes ball
{
  0% { transform: translateY(0); }
  50% { transform: translateY(100%); }
  100% { transform: translateY(0); }
}
@keyframes ball-shadow
{
  0% { width: 60%;height: 60%; }
  50% { width: 90%;height: 90%; }
  100% { width: 60%;height: 60%; }
}
.page-ball {
  position: absolute;
  width: 8.4rem;
  height: 8.4rem;
  z-index: 20;
  .ball-content {
    position: relative;
    z-index: 10;
    width: 100%;
    height: 100%;
    background: $white;
    box-shadow: inset 0.4rem -1rem 2rem #617FFF;
    border-radius: 50%;
    transform: translate3d(0px, 0px, 0px);
    animation-name: ball;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
  .ball-shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 150%;
    transform: rotateX(45deg);
    .shadow-content {
      width: 60%;
      height: 60%;
      background-color: rgba($color: #000000, $alpha: 0.3);
      border-radius: 50%;
      transform: translate3d(0px, 0px, 0px);
      animation-name: ball-shadow;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }
  }
}