@import '../../styles/config';
.background-component {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  overflow: hidden;
  .background {
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 100% auto;
  }
  .background-picture {
    width: 117.4rem;
    height: 94.8rem;
    position: absolute;
    right: -9.5rem;
    top: 5.5rem;
    transform: rotate(30deg);
  }
  .background-window {
    position: absolute;
    top: 30rem;
    right: 9rem;
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0px, 0px, 0px);
    transform: rotate(30deg);
    transition: all 0.7s;
    &.show {
      opacity: 1;
      visibility: visible;
    }
    .window-container {
      position: relative;
      z-index: 10;
      width: 71rem;
      height: 44rem;
      border: 2rem solid $blue;
      border-radius: 5rem;
      overflow: hidden;
      @extend .background;
    }
    .window-border {
      position: absolute;
      left: -3rem;
      top: 5rem;
      width: 100%;
      height: 100%;
      border: 2rem solid $blue2;
      border-radius: 5rem;
    }
    .window-small {
      position: absolute;
      left: 14rem;
      bottom: -10rem;
      z-index: 10;
      width: 43.4rem;
      height: 26rem;
      border: 1.6rem solid $blue;
      border-radius: 3.8rem;
      overflow: hidden;
      filter: drop-shadow(-1.5rem 1rem 0px rgba(0, 60, 142, 0.4));
      @extend .background;
    }
    .window-mini {
      position: absolute;
      left: 16rem;
      bottom: 9rem;
      z-index: 10;
      width: 24.7rem;
      height: 18.2rem;
      border: 1.6rem solid $blue;
      border-radius: 3.8rem;
      overflow: hidden;
      filter: drop-shadow(-1.5rem 1rem 0px rgba(0, 60, 142, 0.4));
      @extend .background;
    }
  }
  .background-phone-wrapper {
    position: absolute;
    top: 29rem;
    right: 16rem;
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0px, 0px, 0px);
    transform: rotate(30deg);
    transition: all 0.7s;
    &.show {
      opacity: 1;
      visibility: visible;
    }
    .background-phone {
      position: relative;
      &.phone1 {
        top: 2rem;
        margin-left: 7rem;
      }
      .phone-container {
        position: relative;
        z-index: 10;
        width: 30rem;
        height: 49.5rem;
        border: 2rem solid $blue;
        border-radius: 5rem;
        overflow: hidden;
        @extend .background;
      }
      .phone-border {
        position: absolute;
        left: -2.5rem;
        top: 5rem;
        width: 100%;
        height: 100%;
        border: 2rem solid $blue2;
        border-radius: 5rem;
      }
    }
  }
  .swiper-pagination {
    bottom: 3rem !important;
    .swiper-pagination-bullet {
      width: 5rem;
      height: 0.6rem;
      border-radius: 0.3rem;
      background-color: $blue7;
      opacity: 1;
      position: relative;
      overflow: hidden;
      &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 100%;
        left: 0;
        top: 0;
        background-color: $white;
        transition: none;
        transform: translate3d(0px, 0px, 0px);
      }
    }
    .swiper-pagination-bullet-active {
      background-color: $blue7;
      &::after {
        width: 100%;
        transition: all 3s linear;
      }
    }
  }
}